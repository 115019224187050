@tailwind base;
@tailwind components;
@tailwind utilities;


.ticketFont{
  font-size: 10px;
  font-weight: 300;
}

.Ticket {
  margin-bottom: 20mm !important; /* Ajusta este valor según tus necesidades */
  background-color: white;
}

@media print {
  html, body {
    background-color: white !important;
  }
  .Ticket {
    margin-bottom: 20mm !important; /* Ajusta este valor según tus necesidades */
    background-color: white;
  }
  .ticketFont{
    font-size: 10px;
    font-weight: 300;
  }
}